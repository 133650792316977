import axios from 'axios';
import { responseMap } from '../utils/axios';

function get() {
  return axios
           .get('/api/tokens')
           .then(responseMap);
}

function destroy(id) {
  return axios
           .delete(`/api/tokens/${id}`);
}

function post({ description }) {
  return axios
           .post('/api/tokens', { description })
           .then(responseMap);
}

const tokensService = { get, destroy, post };

export default tokensService;
