function TableRow({ token, onDelete }) {
  const copyToClipboard = () => {
    navigator
      .clipboard
      .writeText(token.token)
      .then(() => window.alert('Copied'))
      .catch((err) => window.alert('Copy failed: ' + err));
  }

  return (
    <tr>
      <th scope="row">{token.tokenId}</th>
      <td className="pt-2" style={{ minWidth: '400px' }}>
        <div className="input-group">
          <input type="text" className="form-control" readOnly value={token.token} />
          <div className="input-group-append">
            <span className="input-group-text">
              <button className="btn btn-secondary btn-sm rounded" onClick={copyToClipboard}>
                <i className="fa fa-clipboard"></i>
              </button>
            </span>
          </div>
        </div>
      </td>
      <td>{token.description}</td>
      <td className="pt-3">
        <button className="btn btn-secondary btn-sm rounded" onClick={() => onDelete(token)}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
}

export default TableRow;
