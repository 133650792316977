import { Link } from "react-router-dom";

function Card({ col, headerTxt, titleTxt, children }) {
  return (
    <div className={`col-md-${col} mt-3`}>
      <div className="card text-white bg-primary">
        <div className="card-header">{headerTxt}</div>
        <div className="card-body text-dark bg-light">
          <h4 className="card-title">{titleTxt}</h4>
          <p className="card-text">
            {children}
          </p>
        </div>
      </div>
    </div>
  );
}

function HomeView() {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col">
          <h2>zSystem Text-To-Speech API</h2>

          <p>
            HTTP API for generating text-to-speech
            voice files with many different tts providers.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <Card col="4" headerTxt="Text-To-Speech" titleTxt="Create new voice files">
          Start creating <Link to="/tts">new voice files</Link>
        </Card>
        <Card col="4" headerTxt="Tokens" titleTxt="Manage API tokens">
            Create and delete <Link to="/tokens">API tokens</Link>
        </Card>
        <Card col="4" headerTxt="Documentation" titleTxt="HTTP API docs">
            Browse <Link to="/documentation">OpenApi Documentation</Link>
        </Card>
      </div>
    </div>
  );
}

export default HomeView;
