import { useHistory } from 'react-router';
import userService from '../services/user';

function LogoutLink() {
  const history = useHistory();

  const handleOnClick = () => {
    userService
      .logout()
      .then(() => {
        history.push('/');
        window.location.reload();
      });
  }

  return (
    <button className="btn btn-link dropdown-item" onClick={handleOnClick} style={{ textTransform: 'none' }}>
      Logout
    </button>
  );
}

export default LogoutLink;
