import { useState } from 'react';
import { useHistory } from 'react-router';
import voiceFilesService from '../../services/voice-files';
import useInput from '../../utils/input-hook';
import Button from '../Button';
import ErrorsList from '../ErrorsList';
import ProviderInfoModal from './ProviderInfoModal';

function Form({ providers }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const provider = useInput('select', '');
  const voice = useInput('select', '');
  const name = useInput('text', '');
  const content = useInput('text', '');

  const handleSubmit = (e) => {
    e.preventDefault();

    const p = provider.value;
    const v = voice.value;
    const n = name.value;
    const c = content.value;

    if (!p || !v || !n || !c) {
      return false;
    }

    setErrors(null);
    setIsLoading(true);

    voiceFilesService
      .post({
        name: n,
        ttsProvider: p,
        ttsVoice: v,
        ttsContent: c,
      })
      .then(() => {
        setIsLoading(false);
        history.push('/voice-files');
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;

        if (errors) {
          setErrors(errors);
        } else if (err.response?.data?.error) {
          setErrors({
            unknownError: [ err.response.data.message ],
          });
        } else {
          setErrors({
            unknownError: [ 'Saving new tts voice file failed' ],
          });
        }

        setIsLoading(false);
      });

    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="form-label mt-4">
                Provider <ProviderInfoModal providers={providers} />
              </label>
              <select name="provider" className="form-select" onChange={provider.onChange}>
                <option value="">Select Text-To-Speech Provider</option>
                {providers.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="form-label mt-4">
                Voice
              </label>
              {provider.value &&
                <select name="provider" className="form-select" onChange={voice.onChange}>
                  <option value="">Select Text-To-Speech Voice</option>
                  {providers.find(p => p.name === provider.value).voices.map(v => <option key={v} value={v}>{v}</option>)}
                </select>
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label mt-4">
                Name
              </label>
              <input className="form-control" type={name.type} value={name.value} onChange={name.onChange} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label mt-4">
                Content
              </label>
              <textarea className="form-control" rows={5} onChange={content.onChange} value={content.value} />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-4">
            <Button btnClass="success" btnType="submit" isLoading={isLoading} />
          </div>
          <div className="col-6">
              {errors &&
                <ErrorsList errors={errors} />
              }
          </div>
        </div>

      </fieldset>
    </form>
  );
}

export default Form;
