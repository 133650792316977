function Centered({ children }) {
  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Centered;
