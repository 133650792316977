import { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import Form from '../components/tokens/Form';
import TokensTable from '../components/tokens/Table';
import tokensService from '../services/tokens';

function TokensView() {
  const [tokens, setTokens] = useState([]);
  const [deleting, setDeleting] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    tokensService
      .get()
      .then((tokens) => setTokens(tokens));
  }, []);

  const handleOnDelete = (token) => {
    setDeleting(token);
  };

  const handleOnClose = () => setDeleting(null);

  const handleDeleteConfirmed = () => {
    tokensService
      .destroy(deleting.tokenId)
      .then(() => {
        setTokens(tokens.filter(t => t.tokenId !== deleting.tokenId));
        setDeleting(null);
      })
  }

  const handleSubmit = ({ description }) => {
    tokensService
      .post({ description })
      .then((token) => {
        setTokens(tokens.concat(token));
        setCreateModalOpen(false);
      });
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-10 col-8">
          <h1>Tokens</h1>
        </div>
        <div className="col-md-2 col-4">
          <button className="btn btn-primary" onClick={() => setCreateModalOpen(true)}>
            Create new
          </button>
        </div>

        <div className="col-12">

          <TokensTable tokens={tokens} onDelete={handleOnDelete} />
        </div>
      </div>

      <Modal
        isOpen={deleting}
        title="Confirm delete token"
        confirmText="delete"
        confirmTextBtnType="danger"
        onClose={handleOnClose}
        onConfirm={handleDeleteConfirmed}
      >
        <p>Are you sure you want to delete token: {deleting?.description}?</p>
        {tokens.length === 1 &&
          <p className="text-danger fw-bold">
            This seems to be your last token! Deleting it will lock you out!
          </p>
        }
      </Modal>

      {createModalOpen &&
        <Modal
          isOpen={createModalOpen}
          title="Create new token"
          confirmText="save"
          confirmTextBtnType="hidden"
          onClose={() => setCreateModalOpen(false)}
        >
          <Form onSubmit={handleSubmit} />
        </Modal>
      }
    </div>
  )
}

export default TokensView;