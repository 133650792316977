import ErrorBoundary from './components/ErrorBoundary';
import ErrorView from './views/ErrorView';
import LoginView from './views/LoginView';
import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import userService from './services/user';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Loading from './views/Loading';
import VoiceFilesView from './views/VoiceFilesView';
import TtsView from './views/TtsView';
import HomeView from './views/HomeView';
import TokensView from './views/TokensView';
import DocumentationView from './views/DocumentationView';
import ChangePasswordView from './views/ChangePasswordView';

function App() {
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    userService.get()
               .then(user => {
                 setUser(user);
                 setCheckedLogin(true);
                })
               .catch(err => setCheckedLogin(true));
  }, []);

  if (!checkedLogin) {
    return <Loading />;
  }

  if (!user) {
    return <LoginView />;
  }

  return (
    <ErrorBoundary ErrorView={ErrorView}>
      <BrowserRouter>
        <Navbar user={user} />
        <Switch>
          <Route path="/voice-files">
            <VoiceFilesView />
          </Route>
          <Route path="/tts">
            <TtsView />
          </Route>
          <Route path="/tokens">
            <TokensView />
          </Route>
          <Route path="/documentation">
            <DocumentationView />
          </Route>
          <Route path="/password">
            <ChangePasswordView />
          </Route>
          <Route path="/">
            <HomeView />
          </Route>
        </Switch>
      </BrowserRouter>

    </ErrorBoundary>
  );
}

export default App;
