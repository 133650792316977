import '../components/Centered';
import Centered from '../components/Centered';

function ErrorView({ error, errorInfo }) {
  return (
    <Centered>
      <div className="card">
        <div className="card-header text-white bg-danger">Unexpected error occured</div>
        <div className="card-body">
          <h4 className="card-title">{error.toString()}</h4>
          <p className="card-text">Something went wrong...</p>
        </div>
      </div>
    </Centered>
  );
}

export default ErrorView;