import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import axios from 'axios';
import { interceptors } from './utils/axios';

// Add a request interceptor
axios.interceptors.request.use(interceptors.beforeRequest, interceptors.onRequestError)
// Add a response interceptor
axios.interceptors.response.use(interceptors.afterSuccessfulResponse, interceptors.onResponseError)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
