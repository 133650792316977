import TableRow from './TableRow';

function Table({ tokens, onDelete }) {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Token</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((t) => <TableRow key={t.tokenId} token={t} onDelete={onDelete} />)}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
