import { useState } from 'react';
import Players from './Players';
import Modal from '../Modal';

function ListenCol({ voiceFile }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <td className="pt-3">
      <button className="btn btn-secondary btn-sm rounded" onClick={() => setModalIsOpen(true)}>
        <i className="fa fa-music"></i>
      </button>
      {modalIsOpen &&
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} confirmTextBtnType="hidden" closeText="close" title={voiceFile.name}>
          <Players voiceFile={voiceFile} />
        </Modal>
      }
    </td>
  );
}

export default ListenCol;
