import { Link } from 'react-router-dom';
import DataTable, { Col } from '../DataTable';
import StatusPill from './StatusPill';
import ListenCol from './ListenCol';
import moment from 'moment';

function Table({ voiceFiles, onDelete }) {
  if (voiceFiles.length === 0) {
    return (
      <p className="mt-5">
        No voice files found. Go create <Link to="/tts">new ones</Link>.
      </p>
    );
  }

  const cols = [
    new Col('Id', 'voiceFileId', null, true, (a, b) => b - a),
    new Col('Name', 'name', null, true),
    new Col('Length', 'length', null, true, (a, b) => b - a),
    new Col('Status', 'status', (voiceFile) => (
      <td><StatusPill status={voiceFile.status} /></td>
    )),
    new Col('Listen', 'listen', (voiceFile) => (
      <ListenCol voiceFile={voiceFile} />
    )),
    new Col('Expires at', 'expiresAt', (voiceFile) => (
      <td>{moment(voiceFile.expiresAt).format('DD.MM.YYYY HH:mm')}</td>
    )),
    new Col('Action', 'action', (voiceFile) => (
      <td className="pt-3">
        <button className="btn btn-secondary btn-sm rounded" onClick={() => onDelete(voiceFile)}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    )),
  ];

  return (
    <DataTable cols={cols} data={voiceFiles} idProp="voiceFileId" nPerPage="10" defSortBy="voiceFileId" defSortDesc={true} />
  );
}

export default Table;
