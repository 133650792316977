import { useState, Fragment } from 'react';
import useInput from '../utils/input-hook';

export function Col(
  title,
  prop,
  render = null,
  sortable = false,
  sortFun = (a, b) => ('' + a).localeCompare('' + b)
) {
  this.title = title;
  this.prop = prop;
  this.render = render;
  this.sortable = sortable;
  this.sortFun = sortFun;
};

function DataTable({ cols, data, idProp = 'id', nPerPage = 25, defSortBy = '', defSortDesc = true }) {
  const [sortBy, setSortBy] = useState(defSortBy);
  const [sortDesc, setSortDesc] = useState(defSortDesc);
  const [page, setPage] = useState(1);
  const query = useInput('text', '');

  let view = data;

  // Filter by search query
  if (query.value) {
    view = view.filter((row) => {
      // Should improve this
      return JSON.stringify(row).toLowerCase().includes(query.value.toLowerCase());
    });
  }

  // Sort the data
  if (sortBy) {
    const fun = cols.find((c) => c.prop === sortBy).sortFun;
    const sortFun = (sortDesc) ? fun : (a, b) => fun(a, b) * -1;
    view = [...view].sort((rowA, rowB) => sortFun(rowA[sortBy], rowB[sortBy]));
  }

  // Paginate
  const perPage = parseInt(nPerPage);
  const shouldPaginate = view.length > perPage;
  const start = (page - 1) * perPage;
  const end = start + perPage;

  if (shouldPaginate) {
    view = [...view].slice(start, end);
  }

  const totalPages = Math.ceil(data.length / perPage);
  const pageLinks = [...Array(totalPages).keys()].map(n => n+1);

  const navi = (page) => setPage(page);

  return (
    <>
      {view.length > 0 ?
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                {cols.map((c) =>
                  <th
                    key={c.prop}
                    scope="col"
                    onClick={(e) => {
                      if (!c.sortable) return false;
                      const old = sortBy;
                      setSortBy(c.prop);
                      setSortDesc((old === c.prop) ? !sortDesc : true);
                    }}
                    style={{ cursor: (c.sortable) ? 'pointer' : 'default' }}
                  >
                    {c.sortable && sortBy === c.prop &&
                      <i className={`fa fa-${sortDesc ? 'caret-down' : 'caret-up'}`} />
                    }
                    {c.title}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {view.map((row) => (
                <tr key={row[idProp]}>
                  {cols.map(c => {
                    if (c.render) {
                      return <Fragment key={c.prop}>{c.render(row)}</Fragment>;
                    } else {
                      return <td key={c.prop}>{row[c.prop]}</td>;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      :
        <span className="text-info">no data found</span>
      }

      <div>
        {shouldPaginate &&
          <>
            <div className=" d-inline-block">
              <ul className="pagination pagination-sm">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => navi(page-1)}>&laquo;</button>
                </li>
                {pageLinks.map(p =>
                  <li key={p} className={`page-item ${page === p ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => navi(p)}>{p}</button>
                  </li>
                )}
                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => navi(page+1)}>&raquo;</button>
                </li>
              </ul>
            </div>
            <span className="p-2">
              showing {start+1}-{end} of {data.length}
            </span>
          </>
        }
        <div className="form-group d-inline-block pull-right">
          <div className="input-group mb-3">
            <input type={query.type} className="form-control form-control-sm" value={query.value} onChange={query.onChange} />
            <span className="input-group-text">
              <i className="fa fa-search" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTable;
