import { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import VoiceFilesTable from '../components/voice-files/Table';
import voiceFilesService from '../services/voice-files';

function VoiceFilesView() {
  const [voiceFiles, setVoiceFiles] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [showOnlyReady, setShowOnlyReady] = useState(true);

  useEffect(() => {
    voiceFilesService
      .get()
      .then((voices) => setVoiceFiles(voices));
  }, []);

  const handleOnDelete = (voiceFile) => {
    setDeleting(voiceFile);
  };

  const handleOnClose = () => setDeleting(null);

  const handleDeleteConfirmed = () => {
    voiceFilesService
      .destroy(deleting.voiceFileId)
      .then(() => {
        setVoiceFiles(voiceFiles.filter(v => v.voiceFileId !== deleting.voiceFileId));
        setDeleting(null);
      })
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-6">
          <h1>Voice Files</h1>
        </div>
        <div className="col-6">
          <div className="form-check form-switch float-end">
            <label className="form-check-label" htmlFor="only-ready-toggle">Show only ready</label>
            <input className="form-check-input" type="checkbox" role="switch" id="only-ready-toggle" checked={showOnlyReady} onChange={() => setShowOnlyReady(!showOnlyReady)} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {voiceFiles !== null && <VoiceFilesTable voiceFiles={voiceFiles.filter(vf => (showOnlyReady) ? vf.status === 'ready' : true)} onDelete={handleOnDelete} />}
        </div>
      </div>
      <Modal
        isOpen={deleting}
        title="Confirm delete voice file"
        confirmText="delete"
        confirmTextBtnType="danger"
        onClose={handleOnClose}
        onConfirm={handleDeleteConfirmed}
      >
        <p>Are you sure you want to delete voice file {deleting?.name}?</p>
      </Modal>
    </div>
  )
}

export default VoiceFilesView;