export function Player({ title, url }) {
  const basename = url?.split('/').pop();

  return (
    <div className="border border-secondary p-3 mb-3 rounded">
        <div className="row">
          <div className="col-md-2 pt-3">
            <span className="fs-6 fw-light">{title}</span>
          </div>
          <div className="col-md-5">
            <audio src={url} autoPlay={false} controls={true} preload="none" className="w-100" />
          </div>
          <div className="col-md-5 pt-3 d-none d-md-block">
            <a href={url} target="_blank" rel="noreferrer" download>{basename}</a>
          </div>
        </div>
    </div>
  );
}

function Players({ voiceFile }) {
  return (
    <div className="container">

      <div className="row mb-5 gy-3">
        <div className="col-md-6">
          <h6>TTS Provider</h6>
          {voiceFile.ttsProvider}
        </div>
        <div className="col-md-6">
          <h6>TTS Voice</h6>
          {voiceFile.ttsVoice}
        </div>
      </div>

      <div className="row mb-5 gy-3">
        <div className="col-md-3">
          <h6>TTS Content</h6>
        </div>
        <div className="col-md-9">
          <p className="fst-italic">{voiceFile.ttsContent}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Player title="WAV" url={voiceFile.wavUrl} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Player title="MP3" url={voiceFile.mp3Url} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <Player title="IVR WAV" url={voiceFile.ivrWavUrl} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Player title="IVR MP3" url={voiceFile.ivrMp3Url} />
        </div>
      </div>

    </div>
  );
}

export default Players;