import axios from 'axios';
import { responseMap } from '../utils/axios';

function get() {
  return axios
           .get('/api/user')
           .then(responseMap);
}

function login(email, password) {
  return axios
           .post('/api/login', { email, password })
           .then(responseMap);
}

function logout() {
  return axios
           .put('/api/logout');
}

function put({ password, newPassword }) {
  return axios
           .put('/api/user', { password, newPassword })
           .then(responseMap)
}

const userService = { get, login, logout, put };

export default userService;
