import { useState } from "react";

function useInput(type, initial = '') {
  const [value, setValue] = useState(initial);

  const onChange = (event) => {
    setValue(event.target.value)
  };
  const reset = () => setValue(initial);

  return { type, value, onChange, reset, setValue };
}

export default useInput;
