import { useState } from 'react';
import useInput from '../../utils/input-hook';
import Button from '../Button';

function Form({ onSubmit }) {
  const descriptionInput = useInput('text', '');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const description = descriptionInput.value;

    if (!description) {
      return false;
    }

    setIsLoading(true);

    return onSubmit({ description });
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label mt-4">
                Description
              </label>
              <input className="form-control" type={descriptionInput.type} value={descriptionInput.value} onChange={descriptionInput.onChange} />
            </div>
          </div>
        </div>
        <Button btnClass="success mt-3" btnType="submit" isLoading={isLoading} />
      </fieldset>
    </form>
  );
}

export default Form;
