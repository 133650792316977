import { useEffect, useState } from 'react';

import Form from '../components/tts/Form';
import ttsVoicesService from '../services/tts-voices';

function TtsView() {

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    ttsVoicesService
      .get()
      .then(voices => setProviders(voices));
  }, []);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h1>Create New Text-To-Speech Voice File</h1>
          {providers.length > 0 && <Form providers={providers} />}
        </div>
      </div>

    </div>
  );
}

export default TtsView;
