function Button({
  btnClass = 'primary',
  btnType="button",
  btnText = 'Save',
  isLoading = false,
  loadingText = 'Saving',
  onClick = () => false,
}) {
  const content = (isLoading)
    ? (
      <>
        <div className="spinner-border spinner-border-sm"></div>
        <span> {loadingText}</span>
      </>
    ) : (
      <>{btnText}</>
    );


  return (
    <button type={btnType} className={`btn btn-${btnClass}`} onClick={onClick} disabled={isLoading}>
      {content}
    </button>
  );
}

export default Button;
