function StatusPill({ status }) {
  const classes = ['badge', 'rounded-pill'];

  if (status === 'ready') {
    classes.push('bg-success');
  }

  if (status === 'error') {
    classes.push('bg-danger');
  }

  if (status === 'init') {
    classes.push('bg-light');
  }

  return <span className={classes.join(' ')}>{status}</span>;
}

export default StatusPill;
