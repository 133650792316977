import axios from 'axios';
import { responseMap } from '../utils/axios';

function get() {
  return axios
           .get('/api/tts-voices')
           .then(responseMap);
}

const ttsVoicesService = { get };

export default ttsVoicesService;
