import { useState } from 'react';
import Modal from '../Modal';

function BoolIcon({ truth }) {
  return truth ? <i className="fa fa-check text-success" /> : <i className="fa fa-times text-danger" />;
}

function Link({ href }) {
  const maxChars = 60;
  const half = Math.floor(maxChars / 2);

  const text = (href.length > maxChars) ?  href.substr(0, half) + '...' + href.substr(href.length - half - 3) : href;

  return <a href={href} target="_blank" rel="noreferrer" style={{ display: 'block', overflowWrap: 'break-word' }}>{text}</a>
}

function ProviderInfo({ provider }) {
  return(
    <div className="container">
      <div className="row mt-3">
        <div className="col-md-4">
          <h5>{provider.name}</h5>
        </div>
        <div className="col-md-8">
          <p>{provider.description}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <dl className="row">
            <dt className="col-8 text-end">
              Free
            </dt>
            <dt className="col-4">
              <BoolIcon truth={provider.free} />
            </dt>
          </dl>
          <dl className="row">
            <dt className="col-8 text-end">
              Commercial use
            </dt>
            <dt className="col-4">
              <BoolIcon truth={provider.commercialUse} />
            </dt>
          </dl>
        </div>
        <div className="col-md-6">
          {provider.links.map((url) => <Link key={url} href={url} />)}
        </div>
      </div>
      <hr />
    </div>
  );
}


function ProviderInfoModal({ providers }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button className="btn btn-sm btn-light p-0" onClick={() => setShowModal(true)}>
        <i className="fa fa-info" />
      </button>
      <Modal
        isOpen={showModal}
        confirmTextBtnType="hidden"
        onClose={() => setShowModal(false)}
        title="Text-to-speech providers"
      >
        {providers.map((p) => <ProviderInfo key={p.name} provider={p} />)}
      </Modal>
    </>
  );
}

export default ProviderInfoModal;
