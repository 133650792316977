import useInput from '../utils/input-hook';
import Button from '../components/Button';
import ErrorsList from '../components/ErrorsList';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import userService from '../services/user';


function ChangePasswordView() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const currentPwd = useInput('password', '');
  const newPwd = useInput('password', '');
  const confirmPwd = useInput('password', '');

  const handleSubmit = (e) => {
    e.preventDefault();


    if (!currentPwd.value
        || !newPwd.value
        || !confirmPwd.value
    ) {
      return false;
    }

    if (newPwd.value !== confirmPwd.value) {
      setErrors({
        'password-confirm': {
          'passwords-dont-match': 'New password and password confirmation doesn\'t match',
        },
      })

      return false;
    }

    setErrors(null);
    setIsLoading(true);

    userService
      .put({
        password: currentPwd.value,
        newPassword: newPwd.value,
      })
      .then(() => {
        setIsLoading(false);
        history.push('/');
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;

        if (errors) {
          setErrors(errors);
        } else if (err.response?.status === 401) {
          setErrors({
            'password': {
              'unknown-error': 'Invalid current password.',
            },
          });
        } else {
          setErrors({
            'password': {
              'unknown-error': 'Changing password failed.',
            },
          });
        }

        setIsLoading(false);
      });

    return false;
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h1>Change your password</h1>
          <form onSubmit={handleSubmit}>
            <fieldset>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label mt-4">
                      Current password
                    </label>
                    <input className="form-control" type={currentPwd.type} value={currentPwd.value} onChange={currentPwd.onChange} />
                  </div>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label mt-4">
                      New password
                    </label>
                    <input className="form-control" type={newPwd.type} value={newPwd.value} onChange={newPwd.onChange} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label mt-4">
                      Confirm password
                    </label>
                    <input className="form-control" type={confirmPwd.type} value={confirmPwd.value} onChange={confirmPwd.onChange} />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-4">
                  <Button btnClass="success" btnType="submit" isLoading={isLoading} />
                </div>
                <div className="col-6">
                    {errors &&
                      <ErrorsList errors={errors} />
                    }
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordView;
