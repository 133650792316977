import SwaggerUI from 'swagger-ui-react';

/**
 * Compiling this component is super slow...
 */
function DocumentationView() {
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-10">
          <SwaggerUI url="/apidoc/zsystem-tts-openapi.yaml" />
        </div>
      </div>
    </div>
  );
}

export default DocumentationView;
