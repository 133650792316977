import axios from 'axios';
import { responseMap } from '../utils/axios';

function get() {
  return axios
           .get('/api/voice-files')
           .then(responseMap);
}

function destroy(id) {
  return axios
           .delete(`/api/voice-files/${id}`);
}

function post({ name, ttsProvider, ttsVoice, ttsContent }) {
  return axios
           .post('/api/voice-files', { name, ttsProvider, ttsVoice, ttsContent });
}

const voiceFilesService = { get, destroy, post };

export default voiceFilesService;
