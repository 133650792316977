function ErrorsList({ errors = null }) {
  if (!errors) return <></>;

  /**
   * Errors is object:
   * {
   *   propName {
   *     rule: errorMsg
   *   }
   * }
   */

  const errs = Object.entries(errors).map(([prop, valErrs]) => {
    return Object.entries(valErrs).map(([rule, msg]) => msg);
  }).flat();

  return (
    <>
      {errs.map((msg) => <p className="text-danger" key={msg}>{msg}</p>)}
    </>
  );
}

export default ErrorsList;
