export const responseMap = (response) =>
  Promise.resolve(response.data);

export const apiUrl = (path = '') =>
  process.env.REACT_APP_API_URL + path;

const beforeRequest = (config) => {
  // Do something before request is sent
  return config;
};

const onRequestError = (error) => {
  // Do something with request error
  return Promise.reject(error);
};

const afterSuccessfulResponse = (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
};

const onResponseError = (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (error.response?.status === 401
      && !error.request?.responseURL?.includes('/api/user')
      && !error.request?.responseURL?.includes('/api/login')
  ) {
    window.alert('You have been logged out');
    window.location.replace('/');
  }

  return Promise.reject(error);
};

export const interceptors = {
  beforeRequest,
  onRequestError,
  afterSuccessfulResponse,
  onResponseError,
};
