import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoutLink from './LogoutLink';

function NavLink({ path, text }) {
  const location = useLocation();

  const classes = (path) => {
    const className = 'nav-link';
    return (path === location.pathname) ? className + ' active' : className;
  };

  return <Link to={path} className={classes(path)}>{text}</Link>
}

function NavBar({ user = {} }) {
  const [showCollapsedNav, setShowCollapsedNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowCollapsedNav(false);
    setShowDropdown(false);
  }, [location]);

  return (
    <div className="bs-component">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">zSystem TTS API</Link>
          <button className="navbar-toggler" type="button" onClick={() => setShowCollapsedNav(!showCollapsedNav)}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${showCollapsedNav ? 'show' : ''}`} id="navbarColor02">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <NavLink path="/tts" text="TTS" />
              </li>
              <li className="nav-item">
                <NavLink path="/voice-files" text="Voice Files" />
              </li>
              <li className="nav-item">
                <NavLink path="/tokens" text="Tokens" />
              </li>
              <li className="nav-item">
                <NavLink path="/documentation" text="Documentation" />
              </li>
            </ul>
            <div className="d-flex">
              <ul className="navbar-nav me-auto">
                <li className="nav-item dropdown">
                  <button className="btn btn-link nav-link dropdown-toggle" onClick={() => setShowDropdown(!showDropdown)} style={{ textTransform: 'none' }}>
                    <i className="fa fa-user" /> {user.email}
                  </button>
                  <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
                    <Link className="dropdown-item" to="/password">Change password</Link>
                    <div className="dropdown-divider"></div>
                    <LogoutLink />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
