import { useState } from 'react';
import Centered from '../components/Centered';
import userService from '../services/user';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePwdChange = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      return false;
    }

    userService
      .login(email, password)
      .then((d) => {
        window.location.reload()
      })
      .catch(err => {
        window.alert('login failed');
        setEmail('');
        setPassword('');
      });

    return false;
  }

  return (
    <Centered>
      <form onSubmit={handleSubmit}>
        <legend>Login into zSystem TTS API</legend>

        <div className="form-group row">
          <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
          <div className="col-sm-10">
            <input type="text" className="form-control-plaintext" name="email" placeholder="user@example.com" value={email} onChange={handleEmailChange} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
          <div className="col-sm-10">
            <input type="password" className="form-control-plaintext" name="password" value={password} onChange={handlePwdChange} />
          </div>
        </div>

        <button type="submit" className="btn btn-primary mt-2">Login</button>
      </form>
    </Centered>
  );
}

export default Login;
