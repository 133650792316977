import Centered from '../components/Centered';
import './Loading.scss'

/* https://tobiasahlin.com/spinkit/ */
function Loading() {
  return (
    <div className="loading-overlay">
      <Centered>
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      </Centered>
    </div>
  );
}

export default Loading;
