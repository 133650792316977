import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;

    if (this.state.error) {
      return <this.props.ErrorView error={error} errorInfo={errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;